// Responsive breakpoints
var smBreakpoint = 768;
var mdBreakpoint = 992;
var lgBreakPoint = 1200;

var pageHeight = 0;

if ($(window).width() < smBreakpoint)
    var offset = 15;
else if ($(window).width() < mdBreakpoint)
    var offset = 35;
else
    var offset = 70;

$(document).ready(function() {
    initPageHeight();

    initPageScroller();

    initNavigation();
    
    initVideoTeaser();
    
    $('.swipebox').swipebox({
		hideBarsDelay : 0,
		loopAtEnd: true
	});
	
    $(document.body).on('click touchend','#swipebox-slider .current img', function(e) {
        return false;
    }).on('click touchend','#swipebox-slider .current', function(e) {
        $('#swipebox-close').trigger('click');
    });
    
    setTimeout(function() {

        window.scrollTo(0, 0);

    }, 1);
});

$(window).load(function() {
    
    if (location.hash) {

        var $anchor = $('body').find(location.hash);
        
        $('html, body').stop().animate({

            scrollTop: $($anchor).offset().top - $('.navigation-inner').outerHeight() + 1

        }, 3500, 'easeInOutExpo', function() {

            if ($($anchor).attr('id').indexOf('-brand') > -1) {

                $($anchor).parent().addClass('open');
                $($anchor).parent().find('.content').slideDown(500, function() {

                    initSwiper();

                });

            }

        });

        event.preventDefault();

    }

});

function initPageHeight() {
    //resizePageHeight();

    $(window).on('resize', function() {
        //resizePageHeight();
    });
}

function resizePageHeight() {
    $('.page .page-inner').css('height', 'auto');
    pageHeight = $('.page .page-inner').height();
    var height = pageHeight-170;
    $('.page .page-inner').height(height);
}

function initPageScroller() {

    $('a.page-scroll').on('click', function(event) {

        var $anchor = $(this);
        
        if( $($anchor.attr('href').substring($anchor.attr('href').indexOf('#'))).length ) {
            
            $('html, body').stop().animate({
    
                scrollTop: $($anchor.attr('href').substring($anchor.attr('href').indexOf('#'))).offset().top - $('.navigation-inner').outerHeight() + 1
    
            }, 3500, 'easeInOutExpo');
            
            event.preventDefault();
            
        }

    });
    
}

function initNavigation() {
    $(window).on('scroll', function() {
        var scrollPosition = $(this).scrollTop();
        
        if (scrollPosition >= offset) {
            $('header .navigation-outer').css('position', 'fixed');
            if ($(window).width() > smBreakpoint)
                $('header .navigation-outer .navigation').addClass('container');
            $('header .image').css('top', (scrollPosition - offset) / 2);
        } else {
            $('header .navigation-outer').css('position', 'absolute');
            if ($(window).width() > smBreakpoint)
                $('header .navigation-outer .navigation').removeClass('container');
            $('header .image').css('top', 0);
        }
    });
    
    $('header .collapse').on('show.bs.collapse', function () {
        $('header .collapse').not(this).collapse('hide');
        
        $('header .navbar-toggle').removeClass('collapsed').attr('data-target', '#'+$(this).attr('id')).attr('area-expanded', 'true');
        
        if($(this).attr('id') == 'navbar-collapse') {
            
            $('body').addClass('menu-open');
            
        }
    });
    
    $('header .collapse').on('hide.bs.collapse', function () {
        $('header .navbar-toggle').addClass('collapsed').attr('data-target', '#navbar-collapse').attr('area-expanded', 'false');
        
        $('body').removeClass('menu-open');
    });
    
    $('header .collapse a.page-scroll').on('click', function(event) {
        
        if( $($(this).attr('href').substring($(this).attr('href').indexOf('#'))).length ) {
            
            $('header .collapse').collapse('hide');
            
            $('header .navbar-toggle').addClass('collapsed').attr('data-target', '#navbar-collapse').attr('area-expanded', 'false');
            
        }
        
    });
    
}

function initVideoTeaser() {
    
    $('.video-teaser a').on('click', function(event) {
                    
        event.preventDefault();
        
    });
        
    $('.video-teaser-item').hover(function () {
        
        var self = $(this);
        
        setTimeout(function(){
            
            self.parent('a[href]').on('click', '**', function () {
                
                window.location = self.parent('a').attr('href');
                
            });
            
        }, 800);
        
        $(this).addClass('hover');
        
        $(this).closest('.video-teaser').find('.video-teaser-item').not(this).css('width', ( $(this).closest('.video-teaser').width() - 350) / ( $(this).closest('.video-teaser').data('elements') - 1));
        
        $(this).css('width', 350);
        
    }, function () {
        
        $(this).parent('a[href]').off('click', '**');
        
        $(this).removeClass('hover');
        
        $(this).closest('.video-teaser').find('.video-teaser-item').css('width', 100 / $(this).closest('.video-teaser').data('elements') + '%');
        
    });
}